import { styled } from '@mui/material/styles'

const LinkButton = styled('button')(({ theme }) => ({
	flex: 1,
	padding: theme.spacing(20),
	background: '#FFFFFF',
	flexDirection: 'column',
	alignItems: 'flex-start',
	textAlign: 'left',
	boxSizing: 'border-box',
	border: '1px solid #FFFFFF',
	cursor: 'pointer',
	'@keyframes slide': {
		from: { marginLeft: 0 },
		to: { marginLeft: theme.spacing(2) }
	},
	'@keyframes zoomIn': {
		from: { transform: 'scale(1)' },
		to: { transform: 'scale(1.02)' }
	},
	'&:hover': {
		borderColor: theme.palette.green[500],
		'& svg': {
			animation: 'slide 0.2s ease-in-out forwards'
		},
		'& img': {
			animation: 'zoomIn 0.2s ease-in-out forwards'
		}
	}
}))

export default LinkButton
