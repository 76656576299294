import type { FC } from 'react'

import { Link } from '@twelvelabs/tds'
import clsx from 'clsx'
import FullscreenLoading from 'components/FullscreenLoading'
import Routes from 'constants/routes'
import { StatusCodes } from 'http-status-codes'
import ErrorPage from 'pages/_error'
import { useEffect } from 'react'
import useAuth0 from 'utils/hooks/useAuth0'
import useIsOnboardingComplete from 'utils/hooks/useIsOnboardingComplete'
import useRouter from 'utils/hooks/useRouter'

const defaultReturnTo = (): string => window.location.href

interface WithAuthenticationRequiredOptions {
	returnTo?: string | (() => string)
	onRedirecting?: () => JSX.Element
}

const withAuthenticationRequired = <P extends object>(
	Component: FC<P>,
	{
		returnTo = defaultReturnTo,
		onRedirecting = (): JSX.Element => <FullscreenLoading className="z-fullscreen" />
	}: WithAuthenticationRequiredOptions = {}
): FC<P> =>
	function WithCustomAuthenticationRequired(props: P): JSX.Element | null {
		const { error, isLoading, isAuthenticated, loginWithRedirect, logout } = useAuth0()
		const isOnboardingComplete = useIsOnboardingComplete()

		const router = useRouter()

		const handleLogout = () => logout({ returnTo: window.location.origin })

		useEffect(
			function requestLoginWithRedirect(): void | (() => void) {
				if (isAuthenticated || isLoading || error) return

				const opts = {
					returnTo: typeof returnTo === 'function' ? returnTo() : returnTo
				}
				loginWithRedirect(opts)
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[isLoading, isAuthenticated, error, loginWithRedirect, logout, returnTo]
		)

		if (isLoading) return onRedirecting()

		if (error?.message === 'region_not_available') {
			return (
				<ErrorPage
					statusCode={StatusCodes.FORBIDDEN}
					title="Twelve Labs service is not available in your region"
					message={
						<p className={clsx('text-center text-body1')}>
							Please refer to Twelve Labs&apos; <Link href="https://twelvelabs.io/terms-of-use">Terms of use</Link>
						</p>
					}
					action={null}
				/>
			)
		}

		if (error) {
			return (
				<ErrorPage
					statusCode={StatusCodes.INTERNAL_SERVER_ERROR}
					title="Oops, it seems there was a hiccup in the authentication process"
					message="For your security, we recommend logging out and logging back in. If the issue persists, feel free to reach out to our support team."
					action={{ label: 'Logout', onClick: handleLogout }}
				/>
			)
		}

		if (!isOnboardingComplete && isAuthenticated) {
			router.push({ pathname: Routes.ONBOARDING })
			return null
		}

		return isAuthenticated ? <Component {...props} /> : onRedirecting()
	}

export default withAuthenticationRequired
