import 'swiper/css'
import 'swiper/css/pagination'

import { Dialog, DialogActions, DialogContent } from '@mui/material'
import { Button } from '@twelvelabs/tds'
import { useUpdateUserMetadata } from 'apis/user'
import { clsx } from 'clsx'
import Image from 'next/image'
import contentImage1 from 'public/images/new_release_modal_1.png'
import contentImage2 from 'public/images/new_release_modal_2.png'
import contentImage3 from 'public/images/new_release_modal_3.png'
import contentImage4 from 'public/images/new_release_modal_4.png'
import { useState } from 'react'
import { Mousewheel, Pagination, type Swiper } from 'swiper'
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react'
import useAuth0 from 'utils/hooks/useAuth0'
import useIsMobile from 'utils/hooks/useIsMobile'

import styles from './styles.module.css'

const NEW_RELEASE_FLAG = 'view_pegasus_1_release_modal'

const contents = [
	{
		imageSrc: contentImage1,
		header: 'Check out what’s new on playground',
		body: 'Unlock the power of video understanding with our new state of the art models: Marengo & Pegasus.'
	},
	{
		imageSrc: contentImage2,
		header: 'Pegasus1: Generate text from video',
		body: 'Create practice quizzes from lectures. Extract to-dos from meetings. Get police reports from CCTV footage. And so much more! Enjoy the full flexibility to generate any text in any format from your videos.'
	},
	{
		imageSrc: contentImage3,
		header: 'Marengo2.6: Stronger domain knowledge',
		body: 'Marengo 2.6 introduces significant boost in performance, especially in human motions, actions, sport activities, now with multiple language support.'
	},
	{
		imageSrc: contentImage4,
		header: 'Committed to responsible use of AI',
		body: 'Our AI, while state-of-the-art, has limitations. Users may encounter inaccuracies, biases, irrelevant responses, or instances of hallucinations.  We welcome your feedback and will do our best to improve the product in the near future.'
	}
]

const NewReleaseDialog = () => {
	const isMobile = useIsMobile()
	const { userMetadata, checkSession } = useAuth0()
	const updateUserMetadata = useUpdateUserMetadata()

	const didUserViewDialog = userMetadata ? Boolean(userMetadata[NEW_RELEASE_FLAG]) : false

	const [open, setOpen] = useState<boolean>(!didUserViewDialog)
	const closeDialog = async (): Promise<void> => {
		setOpen(false)
		await updateUserMetadata.mutateAsync({ [NEW_RELEASE_FLAG]: true })
		checkSession()
	}

	const [swiper, setSwiper] = useState<Swiper>()
	const [activeIndex, setActiveIndex] = useState(0)

	if (didUserViewDialog) {
		return null
	}

	const isFirstSlide = activeIndex === 0
	const isLastSlide = activeIndex === contents.length - 1

	return (
		<Dialog
			disableEscapeKeyDown
			open={open}
			onClose={closeDialog}
			PaperProps={{ sx: { maxWidth: isMobile ? 'unset' : '550px' } }}
		>
			<DialogContent className="pt-6">
				<div className={styles.sc}>
					<SwiperComponent
						pagination={{ clickable: true }}
						modules={[Mousewheel, Pagination]}
						slidesPerView={1}
						spaceBetween={60}
						onSwiper={setSwiper}
						onSlideChange={(s) => setActiveIndex(s.activeIndex)}
					>
						{contents.map(({ imageSrc, header, body }, index) => (
							<SwiperSlide className="pb-9" key={header}>
								<div className={clsx('flex flex-col items-center')}>
									<Image
										className="h-auto w-full tablet:h-[264px] tablet:w-auto"
										sizes={isMobile ? '100vw' : '100vh'}
										width="0"
										height="0"
										alt={`new_release_promote_img_${index}`}
										src={imageSrc}
										priority
										quality={100}
										placeholder="blur"
									/>
									<p
										className={clsx('text-center font-bold leading-8 text-grey-900', 'text-lg tablet:text-2xl', 'mt-6')}
									>
										{header}
									</p>
									<div
										className={clsx(
											'text-center text-grey-700',
											'text-body2 tablet:text-body1',
											'max-w-[500px]',
											'mt-3'
										)}
									>
										{body}
									</div>
								</div>
							</SwiperSlide>
						))}
					</SwiperComponent>
				</div>
			</DialogContent>
			<DialogActions>
				<div className="flex flex-1 justify-between">
					{isFirstSlide ? (
						<Button className="w-20" type="button" appearance="subtle" onClick={closeDialog}>
							Cancel
						</Button>
					) : (
						<Button className="w-20" type="button" appearance="subtle" onClick={() => swiper?.slidePrev()}>
							Previous
						</Button>
					)}
					{isLastSlide ? (
						<Button className="w-20" type="button" appearance="primary" onClick={closeDialog}>
							Start
						</Button>
					) : (
						<Button className="w-20" type="button" appearance="primary" onClick={() => swiper?.slideNext()}>
							Next
						</Button>
					)}
				</div>
			</DialogActions>
		</Dialog>
	)
}

export default NewReleaseDialog
