import clsx from 'clsx'
import Image from 'next/legacy/image'

const gifSize = 180

interface Props {
	className?: string
}

const FullscreenLoading = ({ className }: Props): JSX.Element => (
	<div className={clsx(className, 'absolute inset-0', 'flex items-center justify-center', 'bg-white')}>
		<Image priority src="/images/horse_loading.gif" width={gifSize} height={gifSize} objectFit="contain" unoptimized />
	</div>
)

export default FullscreenLoading
