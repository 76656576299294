import type { NextPage } from 'next'

import Head from 'next/head'
import withAuthenticationRequired from 'utils/hocs/withAuthenticationRequired'
import HomeWidgets from 'widgets/Home'

const Home: NextPage = () => (
	<>
		<Head>
			<title>Home | Twelve Labs</title>
		</Head>
		<HomeWidgets />
	</>
)

export default withAuthenticationRequired(Home)
