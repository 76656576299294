import type { OverViewBannerCardProps, useOverviewBanner } from './OverviewBanner'

import clsx from 'clsx'
import mixpanel from 'mixpanel-browser'
import Image from 'next/image'

import LinkButton from './common/LinkButton'

const OverviewBannerCard = ({
	title,
	description,
	navigate,
	image,
	disabled,
	badge
}: OverViewBannerCardProps): JSX.Element => (
	<LinkButton
		className={clsx('flex', 'relative', 'font-normal', 'bg-grey-50', 'border-grey-200')}
		onClick={() => {
			navigate()
			mixpanel.track('click', { type: 'button', element: 'main_features', value: title })
		}}
		disabled={disabled}
	>
		<div className={clsx('text-subtitle1 text-grey-1000', 'mb-2')}>{title}</div>
		<div className={clsx('text-body2 text-grey-700', 'mb-5')}>{description}</div>
		<Image className="max-h-[130px] w-full" src={image} alt={`${title} banner-image`} priority width={0} height={0} />
		{badge && (
			<div className={clsx('absolute right-5 top-5', 'px-1 py-0.5', 'bg-green-50')}>
				<p className={clsx('text-subtitle3 !text-green-900')}>{badge}</p>
			</div>
		)}
	</LinkButton>
)

const OverviewBannerDesktop = ({
	title,
	search,
	classify,
	generate
}: ReturnType<typeof useOverviewBanner>): JSX.Element => (
	<div>
		<p className={clsx('mb-5', 'text-heading7 !text-grey-900')}>{title}</p>
		<div className="flex gap-5">
			<OverviewBannerCard {...generate} />
			<OverviewBannerCard {...search} />
			<OverviewBannerCard {...classify} />
		</div>
	</div>
)

export default OverviewBannerDesktop
