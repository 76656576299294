import { clsx } from 'clsx'
import PageContainer from 'components/PageContainer'

import IndexSection from './IndexSection'
import NewReleaseDialog from './NewReleaseDialog'
import OverviewBanner from './OverviewBanner'
import UploadVideos from './UploadVideos'

const Home = (): JSX.Element => (
	<PageContainer className={clsx('flex flex-col gap-y-12 tablet:gap-y-16')}>
		<UploadVideos />
		<OverviewBanner />
		<IndexSection sample />
		<IndexSection />
		<NewReleaseDialog />
	</PageContainer>
)

export default Home
