import type { IndexSectionQueryParam } from 'widgets/IndexSection'

import { Button } from '@twelvelabs/tds'
import { clsx } from 'clsx'
import { pathName } from 'constants/paths'
import mixpanel from 'mixpanel-browser'
import Link from 'next/link'
import ChevronRightIcon from 'public/icons/chevron-right.svg'

const IndexesPageLink = ({ type }: IndexSectionQueryParam) => (
	<Link
		href={pathName.indexes({ type })}
		onClick={() => mixpanel.track('click', { type: 'button', element: 'indexes_full_list', value: { type } })}
	>
		<Button size="sm" appearance="subtle" className={clsx('flex items-center gap-x-1', '-mr-3')}>
			See full list
			<ChevronRightIcon className={clsx('text-inherit', 'h-5 w-5')} />
		</Button>
	</Link>
)

export default IndexesPageLink
