const EXAMPLES = 'examples'

export function getExamplesKey(): [typeof EXAMPLES]
export function getExamplesKey<Params = Record<string, unknown>>(params: Params): [typeof EXAMPLES, Params]
export function getExamplesKey<Params = Record<string, unknown>>(params?: Params): unknown {
	if (!params) return [EXAMPLES]
	return [EXAMPLES, params]
}

export const getExampleKey = (exampleId: string): [typeof EXAMPLES, string] => [EXAMPLES, exampleId]
