import type { ExampleTypeQueryParam } from 'widgets/Example/ExampleTypeSelect'

import { FeatureType } from 'apis/examples'
import { EngineFamily, useFetchIndexHasVideo } from 'apis/indexes'
import { pathName } from 'constants/paths'
import withBoundary from 'utils/hocs/withBoundary'
import useIsMobile from 'utils/hooks/useIsMobile'
import useRouter from 'utils/hooks/useRouter'

import OverviewBannerDesktop from './OverviewBannerDesktop'
import OverviewBannerMobile from './OverviewBannerMobile'

export interface OverViewBannerCardProps {
	title: string
	description: string
	navigate: () => Promise<void>
	image: string
	badge?: string
	disabled?: boolean
	desktopOnly?: boolean
}

export const useOverviewBanner = (): { title: string } & Record<
	'search' | 'classify' | 'generate',
	OverViewBannerCardProps
> => {
	const router = useRouter()

	const fetchIndexHasVideo = useFetchIndexHasVideo()

	const navigateToExample = (t: FeatureType) =>
		router.push<ExampleTypeQueryParam>({ pathname: `${pathName.EXAMPLES}`, params: { t } })

	return {
		title: 'Main features',
		search: {
			title: 'Search',
			description: 'Find specific moments within your videos by describing the scene in natural language.',
			navigate: async () => {
				const [indexContainsVideo] = await fetchIndexHasVideo.mutateAsync({
					sort_by: 'created_at',
					engine_family: EngineFamily.MARENGO
				})
				if (indexContainsVideo) {
					router.push({ pathname: pathName.search(indexContainsVideo._id) })
					return
				}
				navigateToExample(FeatureType.SimpleSearch)
			},
			image: '/images/banner_search.svg'
		},
		classify: {
			title: 'Classify',
			description: 'Build your own classifier using natural language and run instantly.',
			navigate: async () => {
				const [indexContainsVideo] = await fetchIndexHasVideo.mutateAsync({
					sort_by: 'created_at',
					engine_family: EngineFamily.MARENGO
				})
				if (indexContainsVideo) {
					router.push({ pathname: pathName.classify(indexContainsVideo._id) })
					return
				}
				navigateToExample(FeatureType.Classify)
			},
			image: '/images/banner_classify.svg',
			desktopOnly: true
		},
		generate: {
			title: 'Generate',
			description: 'Generate text from videos - summary, chapters, highlights and more.',
			navigate: async () => {
				const [indexContainsVideo] = await fetchIndexHasVideo.mutateAsync({
					sort_by: 'created_at',
					engine_family: EngineFamily.PEGASUS
				})
				if (indexContainsVideo) {
					router.push({ pathname: pathName.generate(indexContainsVideo._id) })
					return
				}
				navigateToExample(FeatureType.Generate)
			},
			image: '/images/banner_generate.svg',
			badge: 'New'
		}
	}
}

const OverviewBanner = (): JSX.Element | null => {
	const props = useOverviewBanner()

	const isMobile = useIsMobile()
	const Component = isMobile ? OverviewBannerMobile : OverviewBannerDesktop
	return <Component {...props} />
}

export default withBoundary(OverviewBanner)
