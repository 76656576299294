import type { InfiniteData } from '@tanstack/react-query'
import type { ClassifyRequestParams } from 'apis/classify'
import type { GenerateParams } from 'apis/generate'
import type { SimpleSearchParams } from 'apis/search'
import type { SummarizeParams } from 'apis/summarize'
import type { AxiosResponse } from 'axios'
import type {
	DefaultResponse,
	PageResponseFormat,
	TwelveLabsApiError,
	UseCommonInfiniteQueryOptions
} from 'utils/response'

import { ApiType, getInstance } from 'utils/network'

import { getExamplesKey } from './keys'

export enum ExampleTagType {
	FEATURE = 'feature',
	INDUSTRY = 'industry'
}

interface ExampleTag {
	_id: string
	name: string
	icon_url?: string
	type: ExampleTagType
}

interface ExampleParams {
	index_id?: string
	priority_threshold?: number
	/**
	 * @default 1
	 */
	page?: number
	/**
	 * @default 10
	 */
	page_limit?: number
	titles?: string[]
	feature_type?: FeatureType
	/**
	 * @default 'priority'
	 */
	sort_by?: 'created_at' | 'updated_at' | 'priority'
	check_invalid_index?: boolean
}

export type ExampleSimpleSearchParams = Pick<
	SimpleSearchParams,
	'search_options' | 'conversation_option' | 'operator' | 'threshold'
> & { query: string }

export interface ExampleClassifyParams {
	class_group_id: string
	classes?: string[]
	include_clips?: boolean
	show_detailed_score?: boolean
	threshold?: Required<ClassifyRequestParams['threshold']>
}

export type ExampleGenerateParams = (GenerateParams | SummarizeParams) & {
	/**
	 * Distinguish mode of generate page by existence of these fields
	 * If value is undefined, it means it will not be used.
	 * If value is null, it means it doesn't use custom prompt.
	 * If value is string type, it means it will define custom prompt.
	 *
	 * So if all these fields are undefined, example will be linked to 'custom' mode of generate page.
	 * And if one of these fields are not undefined, example will be linked to 'template' mode of generate page.
	 */
	summary_prompt?: string | null
	highlights_prompt?: string | null
	chapters_prompt?: string | null
}

export enum FeatureType {
	Classify = 'classify',
	Generate = 'generate',
	SimpleSearch = 'simple_search'
}

export interface ExampleResponse extends DefaultResponse {
	title: string
	description: string
	index_id: string
	feature_type: FeatureType
	priority: number
	params: (ExampleClassifyParams | ExampleGenerateParams | ExampleSimpleSearchParams)[]
	icon_url?: string
	tags?: ExampleTag[]
}

type ExamplePageResponse = PageResponseFormat<ExampleResponse>

type InfiniteExamplesPageParam = number | undefined
export function infiniteExamplesOptions<Response extends ExamplePageResponse>(
	params: Omit<ExampleParams, 'page'>
): UseCommonInfiniteQueryOptions<
	AxiosResponse<Response>,
	TwelveLabsApiError,
	InfiniteData<Response, InfiniteExamplesPageParam>,
	AxiosResponse<Response>,
	ReturnType<typeof getExamplesKey>,
	InfiniteExamplesPageParam
> {
	return {
		queryKey: getExamplesKey(params),
		queryFn: async ({ pageParam: page }) =>
			getInstance(ApiType.PLAYGROUND).get<Response>('/examples', { params: { ...params, page } }),
		select: ({ pages, pageParams }) => ({ pageParams, pages: pages.map(({ data }) => data) }),
		initialPageParam: 1,
		getNextPageParam: ({ data }) =>
			data.page_info.page < data.page_info.total_page ? data.page_info.page + 1 : undefined
	}
}
