import type { Theme } from '@mui/material'
import type { ContainerProps } from '@mui/material/Container'

import { useMediaQuery } from '@mui/material'
import Container, { containerClasses } from '@mui/material/Container'

const PageContainer = ({ sx, ...props }: ContainerProps): JSX.Element => {
	const isMobile = useMediaQuery<Theme>(({ breakpoints }) => breakpoints.down('mobile'))

	return (
		<Container
			maxWidth={false}
			sx={{
				pt: isMobile ? 0 : 40,
				pb: 64,
				maxWidth: 1160,
				[`&.${containerClasses.root}`]: {
					px: isMobile ? 0 : 40
				},
				...sx
			}}
			{...props}
		/>
	)
}

export default PageContainer
