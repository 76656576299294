import './index_section.css'
import clsx from 'clsx'
import Section from 'components/Section'
import { FreePlanAlert } from 'widgets/IndexSection'
import IndexGrid from 'widgets/IndexSection/IndexGrid'

import IndexesPageLink from './IndexesPageLink'

const indexDescription = {
	my: 'Upload your videos to your own index. Choose the engines and options that shape how your videos processed and understood.',
	sample: 'We’ve curated and pre-indexed videos from various industries. Dive right in and try our features.'
}

const IndexSection = ({ className, sample }: { className?: string; sample?: boolean }) => (
	<Section
		id={`${sample ? 'sample' : 'my'}_indexes`}
		className={clsx('px-5 tablet:px-0', '[&>div:first-of-type]:!mb-2.5', className)}
		label={sample ? 'Sample indexes' : 'My indexes'}
		widget={<IndexesPageLink type={sample ? 'sample' : 'my'} />}
	>
		<p className={clsx('text-body1 !text-grey-800', 'mb-5')}>
			{sample ? indexDescription.sample : indexDescription.my}
		</p>
		{!sample && <FreePlanAlert className={clsx('mb-5')} />}
		<IndexGrid sample={sample} limit={3} />
	</Section>
)

export default IndexSection
