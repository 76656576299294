import type { OverViewBannerCardProps, useOverviewBanner } from './OverviewBanner'

import clsx from 'clsx'
import mixpanel from 'mixpanel-browser'
import Image from 'next/image'
import React from 'react'

import LinkButton from './common/LinkButton'

const OverviewBannerCard = ({
	title,
	description,
	navigate,
	image,
	desktopOnly,
	disabled,
	badge
}: OverViewBannerCardProps): JSX.Element => (
	<div className="relative">
		<LinkButton
			className={clsx('flex justify-start', 'p-4', 'bg-grey-50', 'border-grey-200', 'w-full')}
			onClick={() => {
				navigate()
				mixpanel.track('click', { type: 'button', element: 'main_features', value: title })
			}}
			disabled={desktopOnly || disabled}
		>
			<div className={clsx('text-subtitle1 text-grey-1000', 'mb-1')}>{title}</div>
			<div className={clsx('text-body2 text-grey-700', 'mb-4')}>{description}</div>
			<Image
				className={clsx('h-[150px] w-auto', 'self-center')}
				alt={`${title} banner image`}
				src={image}
				height="0"
				width="0"
			/>
		</LinkButton>
		{desktopOnly && (
			<div className={clsx('absolute inset-0', 'bg-white/80')}>
				<div className={clsx('absolute right-4 top-4', 'bg-moss_green-100 px-1 py-0.5')}>
					<p className={clsx('text-subtitle3 !text-secondary')}>Try on desktop</p>
				</div>
			</div>
		)}
		{badge && (
			<div className={clsx('absolute right-4 top-4', 'px-1 py-0.5', 'bg-green-50')}>
				<p className={clsx('text-subtitle3 !text-green-900')}>{badge}</p>
			</div>
		)}
	</div>
)

const OverviewBannerMobile = ({
	title,
	search,
	classify,
	generate
}: ReturnType<typeof useOverviewBanner>): JSX.Element => (
	<div className="px-5">
		<h5 className="mb-5">{title}</h5>
		<div className="flex flex-col gap-y-3">
			<OverviewBannerCard {...generate} />
			<OverviewBannerCard {...search} />
			<OverviewBannerCard {...classify} />
		</div>
	</div>
)

export default OverviewBannerMobile
